import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  Menu,
  Alert,
  MenuItem,
  TextField,
  Avatar,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { db } from "../../shared/firebase";
import { colors } from "../lib/theme";
import { useAuth } from "../../shared/useAuth";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc as firestoreDoc,
  deleteDoc,
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import RefreshLink from "../../shared/RefreshLink";
import ConfirmDialog from "../../shared/ConfirmDialog";
import { Helmet } from "react-helmet";
import ContainerBox from "../../shared/ContainerBox";
import ImageUploader from "../../shared/ImageUploader";
import AlbumList from "../../shared/AlbumList"; // Import the shared component
import axios from 'axios';

const Dashboard = () => {
  const { user, loading } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [albumsLoading, setAlbumsLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedAlbum, setSelectedAlbum] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [showNamePrompt, setShowNamePrompt] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [ownedAlbums, setOwnedAlbums] = useState([]);
  const [sharedAlbums, setSharedAlbums] = useState([]);
  const [subscriptionLevel, setSubscriptionLevel] = useState(null);
  const [profilePhotoURL, setProfilePhotoURL] = useState("");
  const [jobScheduled, setJobScheduled] = useState(false);
  const [userIsDisabled, setUserIsDisabled] = useState(false);
  const [trialDaysRemaining, setTrialDaysRemaining] = useState(null);

  useEffect(() => {
    if (loading) return; // Add this line to prevent early navigation

    if (user && !user.emailVerified) {
      navigate("/verify-email");
    } else if (user) {
      setEmailVerified(true);
    }
  }, [user, navigate, loading]); // Add loading to the dependency array

  useEffect(() => {
    const fetchAlbums = async () => {
      if (user) {
        setAlbumsLoading(true);
        const albumsRef = collection(db, "albums");
        const q = query(albumsRef, where("userId", "==", user.uid));
        const sharedQ = query(
          albumsRef,
          where("sharedWith", "array-contains", user.uid),
          where("privacyLevel", "==", 1)
        );

        const [ownedSnapshot, sharedSnapshot] = await Promise.all([
          getDocs(q),
          getDocs(sharedQ),
        ]);

        const fetchAlbumWithCreator = async (albumDoc) => {
          const albumData = { id: albumDoc.id, ...albumDoc.data() };
          const creatorDoc = await getDoc(
            firestoreDoc(db, "users", albumData.userId)
          );
          const creatorData = creatorDoc.data();

          // Fetch shared users' data
          const sharedUsersData = await Promise.all(
            (albumData.sharedWith || []).map(async (userId) => {
              const userDoc = await getDoc(firestoreDoc(db, "users", userId));
              return userDoc.data();
            })
          );

          return {
            ...albumData,
            creatorName: creatorData
              ? `${creatorData.firstName} ${creatorData.lastName}`
              : "Unknown",
            updatedAt: albumData.updatedAt || albumData.createdAt, // Use updatedAt if available, otherwise fall back to createdAt
            sharedUsers: sharedUsersData,
          };
        };

        const ownedAlbums = await Promise.all(
          ownedSnapshot.docs.map(fetchAlbumWithCreator)
        );
        const sharedAlbums = await Promise.all(
          sharedSnapshot.docs.map(fetchAlbumWithCreator)
        );

        // Sort albums by updatedAt in descending order (most recent first)
        const sortedOwnedAlbums = ownedAlbums.sort(
          (a, b) => b.updatedAt - a.updatedAt
        );
        const sortedSharedAlbums = sharedAlbums.sort(
          (a, b) => b.updatedAt - a.updatedAt
        );

        setOwnedAlbums(sortedOwnedAlbums);
        setSharedAlbums(sortedSharedAlbums);
        setAlbumsLoading(false);
      }
    };

    fetchAlbums();
  }, [user]);

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, "users", user.uid));
          const userData = userDoc.data();
          console.log("Fetched user data:", userData); // Debug log
          setProfilePhotoURL(userData?.profilePhotoURL || "");

          const fetchedSubscriptionLevel = userData?.subscriptionLevel || 0;
          setSubscriptionLevel(fetchedSubscriptionLevel);

          // Calculate trial status and user disabled state
          if (userData?.createdAt) {
            const createdAtDate = userData.createdAt.toDate(); // Convert Firestore Timestamp to Date
            const trialEndDate = new Date(createdAtDate.getTime() + 30 * 24 * 60 * 60 * 1000); // Add 30 days
            const today = new Date();

            const daysRemaining = Math.ceil((trialEndDate - today) / (1000 * 60 * 60 * 24));
            setTrialDaysRemaining(Math.max(0, daysRemaining)); // Ensure it's not negative

            // Check if user should be disabled
            const isDisabled = userData?.disabled === 1 || (fetchedSubscriptionLevel === 0 && today > trialEndDate);
            setUserIsDisabled(isDisabled);
          } else {
            // If createdAt doesn't exist, consider the user disabled as a precaution
            setUserIsDisabled(true);
          }

          if (!userData?.firstName || !userData?.lastName) {
            setShowNamePrompt(true);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    fetchUserData();
  }, [user]);

  useEffect(() => {
    const handleInvitations = async () => {
      if (user) {
        try {
          const albumsRef = collection(db, "albums");
          const albumsSnapshot = await getDocs(albumsRef);

          for (const albumDoc of albumsSnapshot.docs) {
            const albumData = albumDoc.data();
            if (albumData.invitedTo && albumData.invitedTo.includes(user.email.toLowerCase())) {
              console.log(`User invited to album: ${albumDoc.id}`);

              await updateDoc(albumDoc.ref, {
                sharedWith: arrayUnion(user.uid),
                invitedTo: arrayRemove(user.email.toLowerCase()),
              });

              console.log(`Updated sharedWith and removed email from invitedTo for album: ${albumDoc.id}`);
            }
          }
        } catch (error) {
          console.error("Error handling invitations:", error);
        }
      }
    };

    handleInvitations();
  }, [user]);

  useEffect(() => {
    const scheduleAccountDisable = async () => {
      if (user && subscriptionLevel === 0 && !jobScheduled && ownedAlbums.length > 0) {
        try {
          const response = await axios.post('/api/jobs/create', { user_id: user.uid });
          console.log("Scheduled account disable job:", response);
          setJobScheduled(true);
        } catch (error) {
          console.log("Did not schedule account disable job");
          console.error('Failed to schedule account disable job:', error);
        }
      }
    };

    scheduleAccountDisable();
  }, [user, subscriptionLevel, jobScheduled, ownedAlbums.length]);

  const handleMenuOpen = (event, album) => {
    setAnchorEl(event.currentTarget);
    setSelectedAlbum(album);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedAlbum(null);
  };

  const handleDeleteAlbum = () => {
    if (selectedAlbum) {
      setOpenDeleteDialog(true);
    }
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    handleMenuClose();
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteDoc(doc(db, "albums", selectedAlbum.id));
      setOwnedAlbums(
        ownedAlbums.filter((album) => album.id !== selectedAlbum.id)
      );
      setSharedAlbums(
        sharedAlbums.filter((album) => album.id !== selectedAlbum.id)
      );
      handleCloseDeleteDialog();
    } catch (error) {
      console.error("Error deleting album: ", error);
    }
  };

  const handleSaveName = async () => {
    if (firstName.trim() && lastName.trim()) {
      try {
        await updateDoc(doc(db, "users", user.uid), {
          firstName: firstName.trim(),
          lastName: lastName.trim(),
        });
        setShowNamePrompt(false);
      } catch (error) {
        console.error("Error updating user profile:", error);
      }
    }
  };

  const handleUploadSuccess = (downloadURL) => {
    setProfilePhotoURL(downloadURL);
    setShowSuccess(true);
  };

  const handleUploadError = (errorMessage) => {
    setError(errorMessage);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "200px",
        }}
      >
        <CircularProgress sx={{ color: colors.gold }} />
      </Box>
    );
  }

  console.log("userIsDisabled:", userIsDisabled);

  return (
    <ContainerBox>
      <Helmet>
        <title>Dashboard - Golden Record</title>
        <meta
          name="description"
          content="View and manage your Golden Record albums"
        />
        <meta name="apple-itunes-app" content="app-id=6501951254" />
      </Helmet>
      {userIsDisabled ? (
        <Alert severity="error">
          Your free trial has ended, so you currently don't have access to your albums.
          Please consider <RefreshLink to="/profile" sx={{ color: colors.navy, textDecoration: "underline" }}>upgrading to a paid plan</RefreshLink> to continue.
        </Alert>
      ) : user ? (
        <>
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "space-between",
                alignItems: { xs: "stretch", sm: "center" },
                mb: 4,
                gap: 2,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <ImageUploader
                  user={user}
                  onUploadSuccess={handleUploadSuccess}
                  onUploadError={handleUploadError}
                >
                  <Avatar
                    src={profilePhotoURL}
                    alt={`${firstName} ${lastName}`}
                    sx={{ width: 60, height: 60, cursor: "pointer" }}
                  />
                </ImageUploader>
                <Typography
                  variant="h4"
                  sx={{ fontSize: { xs: "1.5rem", sm: "2rem" } }}
                >
                  Your Albums
                </Typography>
              </Box>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => navigate("/album/create")}
                // disabled={
                //   subscriptionLevel !== null &&
                //   subscriptionLevel < 1 &&
                //   ownedAlbums.length >= 1
                // }
                sx={{
                  width: { xs: "100%", sm: "auto" },
                  maxWidth: { xs: "100%", sm: "auto" },
                }}
              >
                {/* {subscriptionLevel !== null ? (
                  <>
                    Add Album
                    {subscriptionLevel < 1 && " (Limit 1 for free users)"}
                  </>
                ) : ( */}
                Create an Album
                {/* )} */}
              </Button>
            </Box>
            {/* {subscriptionLevel < 1 && ownedAlbums.length === 1 && (
              <Alert
                severity="info"
                sx={{ mb: 3, border: `1px solid ${colors.darkNavy}` }}
              >
                Free users can only create one album. Upgrade to add unlimited
                albums! Visit your{" "}
                <RefreshLink
                  to="/profile"
                  style={{ color: colors.black, textDecoration: "underline" }}
                >
                  profile
                </RefreshLink>{" "}
                to upgrade!
              </Alert>
            )} */}
            {subscriptionLevel === 0 && trialDaysRemaining !== null && trialDaysRemaining > 0 && (
              <Alert severity="warning" sx={{ mb: 2 }}>
                <strong>Your free trial ends in {trialDaysRemaining} day{trialDaysRemaining !== 1 ? 's' : ''}.</strong>
                You can use the app freely until then with no restrictions. Once your trial ends, you'll lose access to your albums until you choose to subscribe.
                Visit your <RefreshLink to="/profile" sx={{ color: colors.black, textDecoration: "underline" }}>profile</RefreshLink> to upgrade!
              </Alert>
            )}
            {showNamePrompt && (
              <Box
                sx={{
                  mb: 4,
                  p: 3,
                  borderRadius: 2,
                  backgroundColor: colors.white,
                  border: `1px solid ${colors.gold}`,
                  maxWidth: "600px",
                  marginLeft: 0,
                }}
              >
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Please provide your name
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    alignItems: { xs: "stretch", sm: "center" },
                    gap: 2,
                  }}
                >
                  <TextField
                    label="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    size="small"
                    fullWidth
                  />
                  <TextField
                    label="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    size="small"
                    fullWidth
                  />
                  <Button
                    variant="contained"
                    onClick={handleSaveName}
                    size="small"
                    sx={{ py: 1, px: 2, minWidth: { xs: "100%", sm: "auto" } }}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            )}
            {albumsLoading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                }}
              >
                <CircularProgress sx={{ color: colors.navy }} />
              </Box>
            ) : (
              <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 4 }}>
                <Box sx={{ flex: 1 }}>
                  <AlbumList
                    albums={ownedAlbums}
                    title="Created by you"
                    handleMenuOpen={handleMenuOpen}
                    currentUser={user}
                  />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <AlbumList
                    albums={sharedAlbums}
                    title="Shared with you"
                    handleMenuOpen={handleMenuOpen}
                    currentUser={user}
                  />
                </Box>
              </Box>
            )}
          </Box>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleDeleteAlbum}>Delete</MenuItem>
          </Menu>
          <ConfirmDialog
            open={openDeleteDialog}
            onClose={handleCloseDeleteDialog}
            onConfirm={handleConfirmDelete}
            title="Delete Album"
            content={`Are you sure you want to delete the album "${selectedAlbum?.title}"? This action cannot be undone.`}
          />
          {/* <Box sx={{ mt: 8, textAlign: 'center', backgroundColor: colors.lightestGray, p: 4, borderRadius: 2 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Get the Golden Record iPhone App
        </Typography>
        <Button
          variant="emphasized"
          startIcon={<AppleIcon />}
          href="https://apps.apple.com/app/golden-record/id6501951254"
          target="_blank"
          rel="noopener noreferrer"
        >
          Download on the App Store
        </Button>
      </Box> */}
          {/* <Box sx={{ mt: 8, textAlign: 'center', backgroundColor: colors.lightestGray, p: 4, borderRadius: 2 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Invite a friend to create a Golden Record with you!
        </Typography>
        <Button
          variant="emphasized"
          startIcon={<ShareIcon />}
          href="/questionnaire"
          target="_blank"
          rel="noopener noreferrer"
        >
          Get started
        </Button>
      </Box> */}
        </>
      ) : (
        <Typography>
          No session found. Please{" "}
          <RefreshLink to="/login" sx={{ color: colors.navy, textDecoration: "underline" }}>
            log in
          </RefreshLink>
          .
        </Typography>
      )}
    </ContainerBox>
  );
};

export default Dashboard;
